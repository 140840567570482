import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ImageZoom from "react-medium-image-zoom"
import suratpenegasan from "../images/about/suratpenegasan.jpg"
import batasparoki from "../images/about/batasparoki.jpg"
import peta from "../images/about/peta-sanmare-2015.jpg"
import Image from "../components/image"

const AboutPage = () => {
  const { heroImage, temporaryImage1, rmLucky, rmSyl } = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "about/hero.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      temporaryImage1: file(relativePath: { eq: "about/jesus-mary.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rmLucky: file(relativePath: { eq: "about/profilRomo/RmLucky.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rmSyl: file(relativePath: { eq: "about/profilRomo/RmSylvester.jpg" }) {
        childImageSharp {
          fluid(cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <SEO title="About SanMaRe" />
      <Layout>
        <Img
          fluid={heroImage.childImageSharp.fluid}
          className="about-hero-image"
        />

        <div className="container my-5">
          <div className="text-center">
            <h1 className="text-uppercase display-4">Santa Maria Regina</h1>
            <p className="text-muted lead">(Santa Perawan Maria Ratu)</p>
            <p className="">
              Tiap tanggal 22 Agustus Gereja Katolik Roma merayakan peringatan
              Santa Perawan Maria Ratu (Santa Maria Regina).
            </p>
          </div>
          <p className="about-paragraph">
            Maria disebut Ratu oleh karena dan sebagaimana Kristus adalah Raja.
            Konsili Vatikan II meneruskan tradisi sejak abad IV, menegaskan
            kembali ajaran tentang keratuan Maria: “Ia telah ditinggikan oleh
            Tuhan sebagai Ratu alam semesta, supaya secara lebih penuh
            menyerupai Puteranya” (Lumen Gentium 59). Gelar Ratu diberikan untuk
            menunjukkan secara resmi keadaan SP Maria yang bertahta di sisi
            Puteranya, Raja Kemuliaan. Gelar sebagai Ratu beserta kekuasaannya
            telah diperkenalkan di lingkungan rahib Benediktin sejak awal abad
            XII. Nyanyian yang amat terkenal Salve Regina sudah diketahui dalam
            abad XI. Madah itu merupakan ungkapan khas para rahib dalam
            menyatakan permohonan kepada SP Maria.
          </p>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Img
                fluid={temporaryImage1.childImageSharp.fluid}
                className="h-100"
              />
            </div>
            <div className="col-12 col-lg-6 mt-5 mt-lg-0">
              <h2>
                Mengapa SP Maria layak digelari Ratu? Secara biblis bisa
                dijelaskan sebagai berikut:
              </h2>
              <ol className="about-order-list">
                <li>
                  <h6>
                    Keratuan Maria bisa dimengerti sebagai ambil bagian secara
                    unggul dalam imamat rajawi umat Perjanjian Baru (bdk. 1Ptr
                    2:9-10).
                  </h6>
                  Semua orang dipanggil untuk memerintah bersama Kristus (bdk
                  2Tim 2:12; Why 22:5). SP Maria merupakan yang pertama dari
                  semua orang yang terpanggil untuk memerintah bersama Kristus
                  untuk selama-lamanya.
                </li>
                <li>
                  <h6>
                    Keratuan Maria juga merupakan konsekuensi keikutsertaan
                    Bunda Maria dalam misteri Paska Puteranya yang dinyatakan
                    dalam perendahan diri, penderitaan dan kemuliaan (bdk Flp
                    2:6-11).
                  </h6>
                  Oleh karena Maria telah turut serta dalam merendahkan diri
                  sebagai hamba dan mengalami sengsara bersama Kristus, maka
                  layaklah Bunda Maria mengalami kemuliaan bersama Kristus.
                </li>
                <li>
                  <h6>
                    Keratuan SP Maria adalah tujuan akhir dari perjalanan
                    sebagai murid. Pada akhir hidupnya di dunia SP Maria
                    dipindahkan ke dalam Kerajaan Puteranya (bdk. Kol 1:13) dan
                    menerima kepenuhan “mahkota kehidupan” (bdk Why 2:10; 1Kor
                    9:25).
                  </h6>
                  Tujuan akhir ini mempunyai makna bagi Gereja dan seluruh
                  ciptaan, sebab SP Maria yang kini telah bersatu sepenuhnya
                  dengan Kristus merupakan gambar arah perjalanan sejarah Gereja
                  dan seluruh ciptaan menuju “langit dan bumi yang baru” (Why
                  21:1), suatu kediaman bersama Allah di mana “tidak akan ada
                  lagi perkabungan, atau ratap tangis, atau dukacita” (Why
                  21:4).
                </li>
              </ol>
            </div>
          </div>
          <p className="about-paragraph">
            Paus Pius XII menyebut Maria sebagai Ratu karena ia adalah Bunda
            Kristus dan juga karena seturut kehendak Allah ia memainkan peranan
            yang unik dalam karya Penebusan Tuhan. “…sebagaimana Puteranya,
            Maria mengalahkan maut dan diangkat dengan badan dan jiwanya ke
            dalam kemuliaan surgawi, di mana sebagai ratu ia duduk dalam
            kemegahan di sisi Puteranya, Raja abadi” (Pius XII,
            Munificentissimus Deus; Acta Apostolicae Sedis 42 (1950). Gelar
            Maria sebagai Ratu dinyatakan dalam dokumen Gereja, khususnya dalam
            ensiklik Pius XII’s Ad caeli reginam (Acta Apostolicae Sedis 46
            (1954). Pius XII menegaskan keratuan Maria dengan memasukkan dalam
            kalender liturgi tanggal 31 Mei sebagai pesta Maria Ratu.
          </p>
          <p className="about-paragraph">
            Ketika kalender liturgi diperbaharui pada tahun 1969, pesta SP Ratu
            diubah menjadi tanggal 22 Agustus, yaitu dalam oktaf atau hari ke
            delapan sesudah Hari Raya Pengangkatan SM Maria ke Surga. Pesta
            liturgis yang baru ini bisa dipandang sebagai kelanjutan dari
            ketentuan tentang pengangkatan Maria ke surga, dan sebagai penegasan
            tentang pengantaraan Maria. Pius XII mempersembahkan dunia kepada
            Hati Maria Tak Bernoda, Bunda dan Ratu, pada tanggal 31 Oktober
            1942, sebagai pengakuan publik akan keratuan Maria sebagaimana
            kerajaan Kristus. Rumusannya berbunyi: “Sebagaimana Gereja dan
            seluruh umat manusia dipersembahkan kepada Hati Kudus Yesus… maka
            dengan cara yang sama kami pun mempersembahkan diri untuk
            selama-lamanya kepadamu dan kepada Hatimu yang Tak Bernoda, Bunda
            kami dan Ratu dunia, agar cinta dan perlindunganmu mempercepat
            kemenangan kerajaan Allah” (Acta Apostolicae Sedis 34 (1942).
          </p>
          <div className="my-5">
            <div className="row">
              <div className="col-12 col-lg-4">
                <ImageZoom
                  image={{
                    src: suratpenegasan,
                    style: { width: `100%`, maxWidth: `700px` },
                  }}
                  zoomImage={{ src: suratpenegasan }}
                />
                <p className="text-muted text-center">Surat Penegasan</p>
              </div>
              <div className="col-12 col-lg-4">
                <ImageZoom
                  image={{
                    src: batasparoki,
                    style: { width: `100%`, maxWidth: `700px` },
                  }}
                  zoomImage={{ src: batasparoki }}
                />
                <p className="text-muted text-center">Batas Paroki</p>
              </div>
              <div className="col-12 col-lg-4 my-auto">
                <ImageZoom
                  image={{
                    src: peta,
                    style: { width: `100%`, maxWidth: `700px` },
                  }}
                  zoomImage={{ src: peta }}
                />
                <p className="text-muted text-center">Peta Wilayah Paroki</p>
              </div>
            </div>
          </div>
          <div className="py-5">
            <h2 className="text-center">Profil Pastor</h2>
            <div className="my-4" />
            <div className="row">
              <div className="col-12 col-lg-6">
                <Image data={rmLucky} />
                <p className="my-3 text-muted">
                  Romo Kristoforus Lucky Nikasius Pr, lahir di Jakarta 11
                  Oktober 1983 sebagai anak ketiga dari empat bersaudara dalam
                  keluarganya. Sejak kelas 4 SD, ia sudah mulai tertarik menjadi
                  Romo ketika aktif melayani sebagai Putra Putri Altar (PPA)
                  gereja St. Paskalis di Paroki Cempaka Putih. Sosok teladan
                  iman yang mendorong untuk menjadi Romo pada waktu itu adalah
                  Mgr. Cosmas Michael Angkur O.F.M (pernah menjabat uskup
                  Bogor). Selama menjadi PPA di gereja Paskalis inilah, Romo
                  Lucky dan Mgr Cosmas Michael Angkur O.F.M banyak berinteraksi.
                  Sosok ramah dan teladan iman Monsinyur Angkur membuat Romo
                  Lucky tertarik menjadi imam.
                </p>
                <p className="my-3 text-muted">
                  Romo Lucky memulai pendidikan calon imam pada tahun 2002-2003
                  dengan masuk Tahun Orientasi Rohani di Seminari Tinggi
                  Keuskupan Agung Jakarta. Selanjutnya, beliau melanjutkan
                  kuliah di STF Driyakara 2003-2007. Tahun Orientasi Pastoral di
                  gereja St. Yusuf Batang, Keuskupan Purwokerto dilaluinya
                  selama satu tahun sampai tahun 2008. Setelah itu melanjutkan
                  kuliah untuk mengambil gelar Bakaloreat Teologi di Fakultas
                  Teologi Wedabhakti dan S2 Teologi di Universitas Sanata Dharma
                  Yogyakarta 2008-2012. Tahun 2013 ditahbiskan sebagai diakon
                  lalu ditugaskan di Gereja St. Odilia Citra Raya selama 6 bulan
                  kemudian dikirim ke Gereja Maria Menerima Kabar Gembira,
                  Paroki Bomomani, Papua. Tanggal 8 Agustus 2014, beliau
                  ditahbiskan imam di Gereja Salvator Slipi, Jakarta Barat.
                </p>
                <p className="my-3 text-muted">
                  Kini Romo Lucky telah siap menggembalakan umat di Paroki
                  Bintaro Jaya. Ada perbedaan yang ia hadapi dari sebelumnya.
                  Kalau di Papua, ia juga banyak melakukan kerja fisik karena
                  kondisi lingkungannya, kini di daerah perkotaan sikap
                  menanamkan spiritual yang kuat dan banyak belajar yang akan
                  dilakukan. Permasalahan yang dihadapi akan semakin berat dan
                  kompleks. Romo Lucky juga akan melanjutkan karya dari
                  Romo-Romo di SanMaRe sebelumnya. Dengan mengkombinasikan
                  kegiatan gereja dan banyak mengunjungi umat sebagai penampakan
                  wajah Allah yang murah hati untuk menghantarkan kawanan umat
                  Allah kepada jalan keselamatan.
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <Image data={rmSyl} />
                <p className="my-3 text-muted">
                  Romo Sylvester Nong lahir di Sikka, Flores tanggal 4 Agustus
                  1948 dan ditahbiskan di Wamena, Papua tanggal 7 Juni 1981.
                  Setelah tamat seminari menengah tahun 1969, ia menempuh
                  pendidikan Seminari Tinggi St Paulus di Ledalero hingga tahun
                  1977. Romo Sylvester kemudian menempuh studi teologi di
                  Seminari Tinggi St Paulus, Pineleng, Manado. Ia bertugas di
                  berbagai wilayah di Tanah Air. Hingga tahun 2001 ia bertugas
                  di Paroki Bojong Indah dan setelah bertugas di paroki-paroki
                  lain, ia melayani di Paroki Bekasi (2008 - Agustus 2016).
                  Sejak Agustus 2016, Romo Sylvester menjadi pastor rekan di
                  Gereja Santa Maria Regina, Paroki Bintaro Jaya. Moto Romo
                  Sylvester yang sangat indah “ hidup adalah perjuangan dan
                  usaha terus menerus untuk memuliakan Tuhan dan menjadi berkat
                  bagi sesama”.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default AboutPage
